<template>
  <div>
    <h2>PMGD Privado</h2>

    <div class="row">
      <div class="col-12">
        <card>
          <h2>Convertidor de Excel a 15 Min</h2>
          <ul>
            <li class="text-danger">Subir un archivo .xlsx</li>
            <li class="text-danger">Primera columna debe ser de fechas</li>
            <li class="text-danger">A partir de la segunda fila deben ser datos</li>
          </ul>
          <form role="form">
            <base-input 
              label="Archivo"
              placeholder="Archivo"
              type="file"
              id="file"
              ref="file" 
              v-on:change="handleFileUpload15Minutos($event)"
              multiple="false"
              v-model="modeloArchivo15Min"
            >
            </base-input>

            <base-input label="Operación">
              <select class="form-control" id="operacion" v-model="operacionArchivo15Min">
                <option value="PROMEDIO">Promedio</option>
                <option value="SUMA">Suma</option>
                <option value="FIRST">Primero</option>
                <option value="LAST">Último</option>
              </select>
            </base-input>

            <base-input label="Dirección de agrupación">
              <select class="form-control" id="direccion" v-model="direccionAgrupacion">
                <option value="ADELANTE">Adelante - Ej: 6:30 = {6:30, 6:35, 6:40}</option>
                <option value="ATRAS">Atrás - Ej: 6:30 = {6:20, 6:25,  6:30}</option>
              </select>
            </base-input>

            <button 
              :disabled="!archivo15Min"
              class="btn"
              @click="subirArchivos15Minutos($event)"
            >
              Subir Archivo
            </button>
          </form>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <card>
          <h2>Procesador de archivo Power Bi</h2>
          <ul>
            <li class="text-danger">Subir un archivo .xlsx</li>
            <li class="text-danger">Las columnas usadas son Name, Hora y Value. Están deben venir obligatoriamente. </li>
            <li class="text-danger">No incluir las últimas 3 filas en donde se incluye la suma de valores y descripción del archivo.</li>
          </ul>
          <form role="form">
            <base-input 
              label="Archivo"
              placeholder="Archivo"
              type="file"
              id="file"
              ref="file" 
              v-on:change="handleFileUploadPowerBi($event)"
              multiple="false"
            >
            </base-input>

            <button 
              class="btn"
              @click="subirArchivoPowerBi($event)"
            >
              Subir Archivo
            </button>
          </form>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <card>
          <h2>Concatenador de Excel's (D. Sobarzo)</h2>
          <ul>
            <li class="text-danger">Subir archivos .xls con la misma cantidad de columnas cada uno</li>
            <li class="text-danger">Primera columna de medida y segunda columna de Fecha</li>
            <li class="text-danger">Las columnas restantes serán concatenadas según la fecha</li>
            <li class="text-warning">No incluir símbolos en columnas (Ej: m² o °C)</li>
          </ul>
          <form role="form">
            <base-input 
                label="Archivo"
                placeholder="Archivo"
                v-model="archivoSobarzo"
                type="file"
                id="file"
                ref="file" 
                v-on:change="handleFileUploadSobarzo($event)"
                multiple="true"
            >
            </base-input>

            <ul>
              <li v-for="(archivo, idx) in archivosSobarzos" :key="idx">
                {{archivo.name}}
              </li>
            </ul>
            
            <button class="btn" @click="subirArchivosSobarzos($event)">
                Subir Archivo
            </button>
          </form>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <card>
          <h2>Subir Archivos</h2>
          <ul>
            <li class="text-danger">Subir archivos .csv con dos columnas separadas con ";" cada uno </li>
            <li class="text-danger">Primera columna de fecha</li>
            <li class="text-danger">Segunda columna con el valor que se va a unir</li>
            <li class="text-warning">No incluir símbolos en columnas (Ej: m² o °C)</li>
          </ul>
          <form role="form">
            <base-input 
                label="Archivo"
                placeholder="Archivo"
                v-model="archivoGenerico"
                type="file"
                id="file"
                ref="file" 
                v-on:change="handleFileUploadGenerico($event)"
                multiple="true"
            >
            </base-input>

            <ul>
              <li v-for="(archivo, idx) in archivosGenericos" :key="idx">
                {{archivo.name}}
              </li>
            </ul>
            
            <button class="btn" @click="subirArchivosGenericos($event)">
                Subir Archivo
            </button>
          </form>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <card>
          <h2>Subir Archivos de Temperatura</h2>
          <h3>(Report_T_MODULO_X...)</h3>
          <form role="form">
            <base-input 
                label="Archivo"
                placeholder="Archivo"
                v-model="archivoTemperatura"
                type="file"
                id="file"
                ref="file" 
                v-on:change="handleFileUpload($event)"
                multiple="true"
            >
            </base-input>
          <button class="btn" @click="subirArchivoTemperatura($event)">
              Subir Archivo
          </button>
          </form>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <card>
          <h2>Subir Archivos de Piranómetro Frontal</h2>
          <h3>(2 archivos)</h3>
          <form role="form">
            <base-input 
                label="Archivo"
                placeholder="Archivo"
                v-model="archivoPoaF"
                type="file"
                id="file"
                ref="file" 
                v-on:change="handleFileUploadPoaF($event)"
                multiple="true"
            >
            </base-input>
          <button class="btn" @click="subirArchivoPoaF($event)">
              Subir Archivo
          </button>
          </form>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <card>
          <h2>Subir Archivos de Piranómetro Trasero</h2>
          <h3>(2 archivos)</h3>
          <form role="form">
            <base-input 
                label="Archivo"
                placeholder="Archivo"
                v-model="archivoPoaT"
                type="file"
                id="file"
                ref="file" 
                v-on:change="handleFileUploadPoaT($event)"
                multiple="true"
            >
            </base-input>
          <button class="btn" @click="subirArchivoPoaT($event)">
              Subir Archivo
          </button>
          </form>
        </card>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <card>
          <h2>Subir Archivos de Inversores</h2>
          <h3>(Seleccionar archivos en orden)</h3>
          <form role="form">
            <base-input 
                label="Archivo"
                placeholder="Archivo"
                v-model="archivoInversor"
                type="file"
                id="file"
                ref="file" 
                v-on:change="handleFileUploadInversores($event)"
                multiple="true"
            >
            </base-input>
          <button class="btn" @click="subirArchivosInversores($event)">
              Subir Archivo
          </button>
          </form>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { BaseTable } from "@/components";
import DatePicker from 'vue2-datepicker';


import vueNumeralFilterInstaller from 'vue-numeral-filter';
Vue.use(vueNumeralFilterInstaller, {locale: 'es'});

import dayjs from 'dayjs';
var utc = require('dayjs/plugin/utc');
var customParseFormat = require('dayjs/plugin/customParseFormat')
dayjs.extend(customParseFormat)
dayjs.extend(utc);
require('dayjs/locale/es');
dayjs.locale('es');

export default {
  name: 'main-pmgd',
  components: {
    BaseTable,
    DatePicker,
  },
  data(){
    return {
      // Archivo obtenido de powerBi
      archivoPowerBi: null,


      archivoSobarzo: null,
      archivosSobarzos: null,

      archivoGenerico: null,
      archivosGenericos: null,
      archivoTemperatura: null,
      archivosTemperatura: null,
      archivoPoaF: null,
      archivosPoaF: null,
      archivoPoaT: null,
      archivosPoaT: null,
      archivoInversor: null,
      archivosInversores: null,
      // convertidor a 15 min
      opcionesFormatoFecha: [],
      archivo15Min: null,
      modeloArchivo15Min: null,
      operacionArchivo15Min: 'PROMEDIO',
      direccionAgrupacion: 'ATRAS',
    }
  },
  methods: {
    notifyVue(message, type){
      this.$notify({
        message,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: 'center',
        verticalAlign: 'top',
        type,
        timeout: 5000
      });
    },

    handleFileUploadPowerBi(e){
      this.archivoPowerBi = e.target.files[0];
      // console.log(e);
      // for (var i = 0; i < this.archivosSobarzos.length; i++) {
      //   formData.append('file', this.archivosSobarzos[i]);
      // }
    },

    handleFileUploadSobarzo(e){
      this.archivosSobarzos = e.target.files;
      console.log(e);
      // for (var i = 0; i < this.archivosSobarzos.length; i++) {
      //   formData.append('file', this.archivosSobarzos[i]);
      // }
    },

    handleFileUploadGenerico(e){
      this.archivosGenericos = e.target.files;
    },

    handleFileUpload(e){
      this.archivosTemperatura = e.target.files;
    },

    handleFileUploadPoaF(e){
      this.archivosPoaF = e.target.files;
    },

    handleFileUploadPoaT(e){
      this.archivosPoaT = e.target.files;
    },

    handleFileUploadInversores(e){
      this.archivosInversores = e.target.files;
    },

    handleFileUpload15Minutos(e){
      this.archivo15Min = e.target.files[0];
    },

    async subirArchivos15Minutos(e){
      e.preventDefault();
      if(this.archivo15Min==null){
        this.notifyVue('No se ha escogido un archivo', 'danger');
        return;
      }

      const operacion = this.operacionArchivo15Min;
      const direccion = this.direccionAgrupacion;

      let formData = new FormData();

      formData.append('file', this.archivo15Min);
      formData.append('operacion', operacion);
      formData.append('direccion', direccion);

      const filename = this.archivo15Min.name;
      const filename_raw = filename.split('.').slice(0, -1).join('.');

      this.notifyVue('Subiendo archivo', 'info');

      try {
        const res = await this.$http({
          method: 'POST',
          url: '/api/archivos/archivos15minutos',
          data: formData,
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        let data = new Blob([res.data]);
        let fileURL = window.URL.createObjectURL(data);
        let fileLink = document.createElement('a');
      
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `${filename_raw} - 15 Min.xlsx`);
        document.body.appendChild(fileLink);
      
        fileLink.click();

        this.notifyVue('Archivo descargado', 'success');
      } catch (error) {
        this.notifyVue('Error al leer el archivo', 'danger');
        console.log(error);
      }
    },

    async subirArchivoPowerBi(e){
      e.preventDefault();
      if(this.archivoPowerBi === null){
        this.notifyVue('No se ha escogido un archivo', 'danger');
        return;
      }

      let formData = new FormData();

      formData.append('file', this.archivoPowerBi);

      const filename = this.archivoPowerBi.name;
      const filename_raw = filename.split('.').slice(0, -1).join('.');

      this.notifyVue('Subiendo archivo', 'info');

      try {
        let res = await this.$http({
          method: 'POST',
          url: '/api/archivos/powerbi/',
          data: formData,
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        let data = new Blob([res.data]);
        let fileURL = window.URL.createObjectURL(data);
        let fileLink = document.createElement('a');
      
        fileLink.href = fileURL;
        fileLink.setAttribute('download', `${filename_raw} - Procesado.xlsx`);
        document.body.appendChild(fileLink);
      
        fileLink.click();

        this.notifyVue('Archivo descargado', 'success');
      } catch (error) {
        this.notifyVue('Error al leer el archivo', 'danger');
        console.log(error);
      }
    },

    async subirArchivosSobarzos(e){
      e.preventDefault();
      if(this.archivosSobarzos==null){
        this.notifyVue('No se han escogido archivos', 'danger');
        return;
      }

      let formData = new FormData();

      for (var i = 0; i < this.archivosSobarzos.length; i++) {
        formData.append('file', this.archivosSobarzos[i]);
      }

      try {
        let res = await this.$http({
          method: 'POST',
          url: '/api/pmgd/sobarzo',
          data: formData,
          responseType: 'blob',
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        let data = new Blob([res.data]);
        let fileURL = window.URL.createObjectURL(data);
        let fileLink = document.createElement('a');
      
        fileLink.href = fileURL;
        // fileLink.setAttribute('download', `${filename}.${extension}`);
        fileLink.setAttribute('download', `Archivo Sobarzo.xlsx`);
        document.body.appendChild(fileLink);
      
        fileLink.click();

        this.notifyVue('Archivo descargado', 'success');


        // this.notifyVue('Correo enviado', 'success');
      } catch (error) {
        this.notifyVue('Error al leer el archivo', 'danger');
        console.log(error);
      }
    },

    async subirArchivosGenericos(e){
      e.preventDefault();
      if(this.archivosGenericos==null){
        this.notifyVue('No se han escogido archivos', 'danger');
        return;
      }

      let formData = new FormData();
      // formData.append('file', this.archivosTemperatura);
      // this.archivosTemperatura.forEach(element => {
      //   console.log(element);
      // });

      formData.append('n', this.archivosGenericos.length);

      for (var i = 0; i < this.archivosGenericos.length; i++) {
        formData.append('file', this.archivosGenericos[i]);
      }

      try {
        let res = await this.$http({
          method: 'POST',
          url: '/api/pmgd/generico',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
            // 'responseType': 'blob',
          },
        });

        // let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' });
        // let url = window['URL'].createObjectURL(blob);
        // let a = document.createElement('a');
        // a.href = url;
        // a.download = ' discount draft.xlsx';
        // a.click();
        // window['URL'].revokeObjectURL(url);

        this.notifyVue('Correo enviado', 'success');
      } catch (error) {
        this.notifyVue('Error al leer el archivo', 'danger');
        console.log(error);
      }
    },

    async subirArchivoTemperatura(e){
      e.preventDefault();
      if(this.archivosTemperatura==null){
        this.notifyVue('No se ha escogido un archivo', 'danger');
        return;
      }

      let formData = new FormData();
      // formData.append('file', this.archivosTemperatura);
      // this.archivosTemperatura.forEach(element => {
      //   console.log(element);
      // });

      formData.append('n', this.archivosTemperatura.length);

      for (var i = 0; i < this.archivosTemperatura.length; i++) {
        formData.append('file', this.archivosTemperatura[i]);
      }

      try {
        let res = await this.$http({
          method: 'POST',
          url: '/api/pmgd/temperatura',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
            // 'responseType': 'blob',
          },
        });

        // let blob = new Blob([res.data], { type: 'application/vnd.ms-excel' });
        // let url = window['URL'].createObjectURL(blob);
        // let a = document.createElement('a');
        // a.href = url;
        // a.download = ' discount draft.xlsx';
        // a.click();
        // window['URL'].revokeObjectURL(url);

        this.notifyVue('Correo enviado', 'success');
      } catch (error) {
        this.notifyVue('Error al leer el archivo', 'danger');
        console.log(error);
      }
    },

    async subirArchivoPoaT(e){
      e.preventDefault();
      if(this.archivosPoaT==null){
        this.notifyVue('No se ha escogido un archivo', 'danger');
        return;
      }

      let formData = new FormData();

      formData.append('n', this.archivosPoaT.length);

      for (var i = 0; i < this.archivosPoaT.length; i++) {
        formData.append('file', this.archivosPoaT[i]);
      }

      try {
        let res = await this.$http({
          method: 'POST',
          url: '/api/pmgd/poa-t',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        this.notifyVue('Correo enviado', 'success');
      } catch (error) {
        this.notifyVue('Error al leer el archivo', 'danger');
        console.log(error);
      }
    },

    async subirArchivoPoaF(e){
      e.preventDefault();
      if(this.archivosPoaF==null){
        this.notifyVue('No se ha escogido un archivo', 'danger');
        return;
      }

      let formData = new FormData();

      formData.append('n', this.archivosPoaF.length);

      for (var i = 0; i < this.archivosPoaF.length; i++) {
        formData.append('file', this.archivosPoaF[i]);
      }

      try {
        let res = await this.$http({
          method: 'POST',
          url: '/api/pmgd/poa-f',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        this.notifyVue('Correo enviado', 'success');
      } catch (error) {
        this.notifyVue('Error al leer el archivo', 'danger');
        console.log(error);
      }
    },

    async subirArchivosInversores(e){
      e.preventDefault();
      if(this.archivosInversores==null){
        this.notifyVue('No se ha escogido un archivo', 'danger');
        return;
      }

      let formData = new FormData();

      formData.append('n', this.archivosInversores.length);

      for (var i = 0; i < this.archivosInversores.length; i++) {
        formData.append('file', this.archivosInversores[i]);
      }

      try {
        let res = await this.$http({
          method: 'POST',
          url: '/api/pmgd/inversores',
          data: formData,
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        this.notifyVue('Correo enviado', 'success');
      } catch (error) {
        this.notifyVue('Error al leer el archivo', 'danger');
        console.log(error);
      }
    }
  },
  mounted(){
    
  }
}
</script>

<style lang="scss">

</style>